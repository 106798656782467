<template>
    <div class="container">
        <top-bar ref="topBar" ></top-bar>
        <!-- 搜索 -->
        <div class="searchPart" :style="{padding:isMoPhone?'0px':'50px 0px', }">
            <h1 :style="{margin:isMoPhone?'10px 0px':'21px 0px', }">搜索</h1>
            <el-input placeholder="请输入关键字" v-model="inputValue" class="input-with-select" @keyup.enter.native="clickSearchBtn">
                <el-button @click="clickSearchBtn"  slot="append" icon="el-icon-search"></el-button>
            </el-input>
        </div>
        <!-- 内容 -->
        <div class="main" >
          <el-row :gutter="0" style="width:100%">
            <el-col :xs="24" :sm="24" :md="8" :lg="7" :xl="6" style="padding:0px;">
                <div :style="{width:isMoPhone?'100%':'93%', marginBottom:isMoPhone?'15px':'0px'}" >
                    <!-- 热门搜索 -->
                    <div class="hotSearch">
                        <div class="hotTitle">热门搜索</div>
                        <div class="hotContent">
                            <div class="hotText" v-for="(item,index) in hotTextArr" :key="index" @click="clickHotText(item)">{{item.title}}</div>
                        </div>
                    </div>
                    <side-bar ref="sideBar"  :asideList="asideList" :activeMenu="activeMenu" @currentAside = currentAside>
                    </side-bar>
                   
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="16" :lg="17" :xl="18" style="padding:30px;background:#fff;">
                <div class="contentPart">
                    <!-- <div class="topTitle">关键字&nbsp;{{this.searchText}}</div> -->
                    <div class="topTitle">搜索出 {{asideList[0].childList[0].num?asideList[0].childList[0].num:0}} 个内容</div>
                    <hr  class="hr">
                    <div v-loading="loading">
                        <!-- 资料下载 -->
                        <div class="module" v-show="this.$route.query.t=='all'||activeMenu.t=='all'||this.$route.query.t=='downloads'||activeMenu.t=='downloads'">
                        <div class="title">
                                <span class="text">资料下载</span>
                                <span>（{{allData&&allData.filesSize?allData.filesSize:0}}）</span>
                            </div>
                            <div class="greyBox_container" >
                                <div class="greyBox">
                                    <div class="thead">
                                        <div class="td1">资料名称</div>
                                        <div class="td2">资料简介</div>
                                        <div class="td3">版本</div>
                                        <div class="td4">上传时间</div>
                                    </div>
                                    <div v-if="allData&&allData.files" class="second">
                                        <!-- 循环 -->
                                        <div v-for="(item,index) in allData.files" :key="index">
                                            <div v-if="item.fileList.length != 0">
                                                <div class="secondTitle">{{ item.name }}</div>
                                                <div class="tr" v-for="(item,index) in item.fileList" :key="index">
                                                    <div class="td1" @click="clickResourceName(item)">{{item.name}}</div>
                                                    <div class="td2">{{item.content}}</div>
                                                    <div class="td3">{{item.version}}</div>
                                                    <div class="td4">{{item.uploadTime}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <!-- 产品中心 -->
                        <div class="module" v-show="this.$route.query.t=='all'||activeMenu.t=='all'||this.$route.query.t=='products'||activeMenu.t=='products'">
                        <div class="title">
                                <span class="text">产品中心</span>
                                <span>（{{allData&&allData.productsSize?allData.productsSize:0}}）</span>
                            </div>
                            <div v-if="allData&&allData.products&&allData.products.length>0">
                                <div class="greyLi" v-for="(item,index) in allData.products" :key="index" @click="clickProductLi(item)">
                                    {{item.title}}
                                </div>
                            </div>
                            <div  v-else class="greyLi" style="cursor: initial;">暂无产品中心</div>
                        </div>
                        <!-- 应用方案 -->
                        <div class="module" v-show="this.$route.query.t=='all'||activeMenu.t=='all'||this.$route.query.t=='applications'||activeMenu.t=='applications'">
                        <div class="title">
                                <span class="text">应用方案</span>
                                <span>（{{allData&&allData.applicationSize?allData.applicationSize:0}}）</span>
                            </div>
                            <div v-if="allData&&allData.application&&allData.application.length>0">
                                <div class="greyLi" v-for="(item,index) in allData.application" :key="index"  @click="clickApplicationLi(item)">
                                    {{item.title}}
                                </div>
                            </div>
                            <div v-else class="greyLi" style="cursor: initial;">暂无应用方案</div>
                        </div>
                        <!-- 视频资料 -->
                        <div class="module" v-show="this.$route.query.t=='all'||activeMenu.t=='all'||this.$route.query.t=='videos'||activeMenu.t=='videos'">
                        <div class="title">
                                <span class="text">视频资料</span>
                                <span>（{{allData&&allData.videosSize?allData.videosSize:0}}）</span>
                            </div>
                            <div v-if="allData&&allData.videos&&allData.videos.length>0">
                                <div class="greyLi" v-for="(item,index) in allData.videos" :key="index" @click="clickVideosLi(item)">
                                    {{item.title}}
                                </div>
                            </div>
                            <div  v-else class="greyLi" style="cursor: initial;">暂无视频资料</div>
                        </div>
                        <!-- 新闻动态 -->
                        <div class="module" v-show="this.$route.query.t=='all'||activeMenu.t=='all'||this.$route.query.t=='news'||activeMenu.t=='news'">
                        <div class="title">
                                <span class="text">新闻动态</span>
                                <span>（{{allData&&allData.newsSize?allData.newsSize:0}}）</span>
                            </div>
                            <div v-if="allData&&allData.news&&allData.news.length>0">
                                <div class="greyLi" v-for="(item,index) in allData.news" :key="index" @click="clickNewsLi(item)">
                                    {{item.title}}
                                </div>
                            </div>
                            <div  v-else class="greyLi" style="cursor: initial;">暂无新闻动态</div>
                        </div>
                    </div>
                    
                </div>
            </el-col>
          </el-row>
        </div>
        <!-- 底部 -->
        <footer-part ref="footerPart"></footer-part>
    </div>
</template>
<script>
import topBar from '@/pages/topbar.vue'
import footerPart from '@/pages/components/footer.vue'
import sideBar from '@/pages/components/sidebar'
import { searchHttp } from '@/services/serviceSupport.js'
export default {
  name: "search",
  components:{
    topBar,
    footerPart,
    sideBar,
  },
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        inputValue:'',
        searchText:'',
        activeMenu:{},
        asideList:[
          {
            asideName:'',
            childList:[
              {key:'1', asideName:'全部', num:0, t:'all', path:'/search.html'},
              {key:'2', asideName:'资料下载', num:0, t:'downloads', path:'/search.html'},
              {key:'3', asideName:'产品中心', num:0, t:'products', path:'/search.html'},
              {key:'4', asideName:'应用方案', num:0, t:'applications', path:'/search.html'},
              {key:'5', asideName:'视频资料', num:0, t:'videos', path:'/search.html'},
              {key:'6', asideName:'新闻动态', num:0, t:'news', path:'/search.html'},
            ]
          }
        ],
        hotTextArr:[
            {title:"BLE单片机", link:"https://www.wch.cn/products/productsCenter?categoryId=5"},
            {title:"RISC-V", link:"https://www.wch.cn/search.html?q=RISC-V&t=all"},
            {title:"USB单片机", link:"https://www.wch.cn/products/productsCenter?categoryId=5"},
            {title:"MCU", link:"https://www.wch.cn/products/productsCenter?categoryId=5"},
            {title:"USB PD", link:"https://www.wch.cn/search.html?q=USB+PD&t=all"},
            {title:"以太网", link:"https://www.wch.cn/search.html?q=以太网&t=all"},
            {title:"USB转串口", link:"https://www.wch.cn/search.html?q=USB转串口&t=all"},
            {title:"Ethernet", link:"https://www.wch.cn/search.html?q=Ethernet&t=all"},
            {title:"serial", link:"https://www.wch.cn/search.html?q=serial&t=all"},
        ],
        loading:false,
        allData:{},
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 992) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    console.log('mounted--',this.$route.path)
    if (this.windowWidth < 992) {
      this.isMoPhone = true
    }else{
      this.isMoPhone = false
    }
    if(this.$route.query.q){
        this.inputValue = this.$route.query.q
    }
    if(this.$route.query.t){
      for(let item of this.asideList[0].childList){
        if(item.t == this.$route.query.t){
          this.activeMenu = item
          break
        }
      }
    }else{
        this.activeMenu = {key:'1', asideName:'全部', num:0, t:'all', path:'/search.html'}
    }
    document.title = '搜索 - 南京沁恒微电子股份有限公司'
    
    this.$nextTick(()=>{
        // if(this.$route.query.q){
        //     this.getPreData('1')
        // }else{
            this.getAllData('1')
        // }
        this.$router.push({ path: '/search.html'})
    })
  },
  activated(){
    console.log('activated')
    if(this.$route.query.q){
        this.inputValue = this.$route.query.q
    }
    if(this.$route.query.t){
      for(let item of this.asideList[0].childList){
        if(item.t == this.$route.query.t){
          this.activeMenu = item
          break
        }
      }
    }else{
        this.activeMenu = {key:'1', asideName:'全部', num:0, t:'all', path:'/search.html'}
    }
    document.title = '搜索 - 南京沁恒微电子股份有限公司'
   
    this.$nextTick(()=>{
        // if(this.$route.query.q){
        //     this.getPreData('1')
        // }else{
            this.getAllData('1')
        // }
        this.$router.push({ path: '/search.html'})
    })
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    //点击搜索按钮
    clickSearchBtn(){
        if(!this.$route.query.t){//没有路由参数情况下默认 全部
            this.activeMenu = {key:'1', asideName:'全部', num:0, t:'all', path:'/search.html'}
        }
        // this.$router.push({ path: '/search.html', query:{t:this.activeMenu.t, q:this.inputValue}})
        this.getAllData()
    },
    //点击侧边栏
    currentAside(item){
      console.log('点击的menu',item)
      this.activeMenu = item
      
    //   this.$router.push({ path: item.path, query:{t:item.t, q:this.inputValue}})
    },
    //点击热门搜索
    clickHotText(t){
      window.location.href = t.link
    },
   
    //预搜索
    getPreData(sign){
        if(!this.inputValue){
            if(sign){//页面初始化
               return
            }else{
                alert('搜索关键字不能为空！')
                return
            }
        }
        this.searchText = this.inputValue
        let params = {
            searchStr:this.inputValue,
            type:'pre',
        }
       
        this.loading = true;
        this.allData = {}
        searchHttp(params).then(res => {
            if(res.status===200&&res.data.data){
                this.allData = res.data.data
                if(this.allData.totalSize===0){
                    this.$router.push({name:'404'})
                    return
                    this.asideList[0].childList[0].num = this.allData.totalSize?this.allData.totalSize:0
                    this.asideList[0].childList[1].num = this.allData.filesSize?this.allData.filesSize:0
                    this.asideList[0].childList[2].num = this.allData.productsSize?this.allData.productsSize:0
                    this.asideList[0].childList[3].num = this.allData.applicationSize?this.allData.applicationSize:0
                    this.asideList[0].childList[4].num = this.allData.videosSize?this.allData.videosSize:0
                    this.asideList[0].childList[5].num = this.allData.newsSize?this.allData.newsSize:0
                }else{
                    this.getAllData(sign)
                }
            }else{
                if(!res.data.data&&!res.data.message){
                    this.inputValue = ''
                    this.searchText = ''
                    document.title = '搜索 - 南京沁恒微电子股份有限公司'
                    this.$router.push({name:'404'})
                }else{
                    this.$message.error(res.data.message);
                }
            }
            this.loading = false;
        });
    },

    getAllData(sign){
        if(!this.inputValue){
            if(sign){//页面初始化
               return
            }else{
                alert('搜索关键字不能为空！')
                return
            }
        }
        this.searchText = this.inputValue
        let params = {
            searchStr:this.inputValue,
        }
       
        this.loading = true;
        this.allData = {}
        searchHttp(params).then(res => {
            if(res.status===200&&res.data.data){
                this.allData = res.data.data
                // console.log('allData---',this.allData.files)
                this.asideList[0].childList[0].num = this.allData.totalSize?this.allData.totalSize:0
                this.asideList[0].childList[1].num = this.allData.filesSize?this.allData.filesSize:0
                this.asideList[0].childList[2].num = this.allData.productsSize?this.allData.productsSize:0
                this.asideList[0].childList[3].num = this.allData.applicationSize?this.allData.applicationSize:0
                this.asideList[0].childList[4].num = this.allData.videosSize?this.allData.videosSize:0
                this.asideList[0].childList[5].num = this.allData.newsSize?this.allData.newsSize:0
            }else{
                if(!res.data.data&&!res.data.message){
                    this.inputValue = ''
                    this.searchText = ''
                    document.title = '搜索 - 南京沁恒微电子股份有限公司'
                }else{
                    this.$message.error(res.data.message);
                }
            }
            this.loading = false;
        });
    },
    
    //点击资料
    clickResourceName(t){
        let pName = t.name.split('.').join('_')
        // this.$router.push({ name: 'ResourceDetail', params: {name:pName+'.html'}})
        let url = this.$router.resolve({ name: 'ResourceDetail', params: {name:pName+'.html'}}).href
        window.open(url,'_blank')
    },
    //点击产品中心
    clickProductLi(t){
        // this.$router.push({ name: 'ChipDetail', params: {productName:t.alias+'.html'}})
        let url = this.$router.resolve({name: 'ChipDetail', params: {productName:t.alias+'.html'}}).href
        window.open(url,'_blank')
    },
    //点击应用方案
    clickApplicationLi(t){
        // this.$router.push({ name: 'ApplicationDetail', params: {applicationId:t.id+'.html'}})
        let url = this.$router.resolve({ name: 'ApplicationDetail', params: {applicationId:t.id+'.html'}}).href
        window.open(url,'_blank')
    },
    //点击视频资料
    clickVideosLi(t){
        // this.$router.push({ name: 'singleVideo',params: {name:t.name+'.html'}})
        let url = this.$router.resolve({ name: 'singleVideo',params: {name:t.name+'.html'}}).href
        window.open(url,'_blank')
    },
    //点击新闻动态
    clickNewsLi(t){
        // this.$router.push({ name: 'NewItem', params: {id:t.id+'.html'}})
        let url = this.$router.resolve({ name: 'NewItem', params: {id:t.id+'.html'}}).href
        window.open(url,'_blank')
    },

   
    
  }
};
</script>
<style scoped lang="less">
 .container{
    width: 100%;
    margin: 0 auto;
    margin-top: 98px;
    overflow: auto;
 }
 .searchPart{
    width: 55%;
    margin: 0 auto;
    padding: 50px 0px;
    h1{
        font-weight: 500;
    }
 }
 .main {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 4px;
    .hotSearch{
            min-height: 30px;
            background-color: #ffffff;
            margin-bottom: 10px;
            box-shadow: -2px 1px 10px 1px rgba(159,159,159,.2);
            padding: 10px;
        .hotTitle{
            font-size: 16px;
            line-height: 20px;
            margin: 10px 0px ;
            display: block;
            color: #000000;
            border-left: 2px transparent solid;
            text-align: left;
        }
        .hotContent{
            display: flex;
            flex-wrap: wrap;
            .hotText{
                padding-left: 8px;
                width: 50%;
                box-sizing: border-box;
                background-color: #fff;
                color: #1ea9fb;
                cursor: pointer;
                text-align: left;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .contentPart{
        text-align: left;
        .topTitle{
            font-size: 22px;
            color: #002c85;
            font-weight: 600;
            letter-spacing: 0;
            margin-bottom: 20px;
            text-align: left;
        }
        .hr{
            border-top: 1px solid #cbd0d4;
            margin: 25px 0px;
        }
        .module{
            .title{
                height: 32px;
                margin-top: 8px;
                border-bottom: 1px solid #b7dfff;
                margin-bottom: 20px;
                color: #232323;
                font-size: 16px;
                line-height: 24px;
                text-align: left;
                .text{
                    margin: 0;
                    padding-bottom: 6px;
                    min-width: 70px;
                    border-bottom: 2px solid #0275d1;
                    float: left;
                }
            }
            .greyBox_container{
                width: 100%;
            }
            .greyBox{
                background-color: #f5f5f5;
                padding: 15px;
                .thead{
                    display: flex;
                    font-size: 14px;
                    font-weight: 600;
                    border-bottom: 2px solid #ddd;
                }
                .second{
                    border-bottom: 1px solid #ddd;
                }
                .secondTitle{
                    margin: 8px;
                    font-size: 16px;
                    color: #122b40;
                }
                .tr{
                    
                    display: flex;
                    font-size: 15px;
                    padding: 0px 6px;
                    .td1{
                       color: #0275d1;
                       cursor: pointer;
                        &:hover{
                            text-decoration: underline;
                        }
                    }
                }
                .td1{
                    width:160px;
                    padding: 8px;
                    word-break: break-all;
                }
                .td2{
                    flex: 1;
                    padding: 8px;
                    word-break: break-all;
                }
                .td3{
                    width:80px;
                    padding: 8px;
                }
                .td4{
                    width:90px;
                    padding: 8px;
                }
            }
            .greyLi{
                min-height: 50px;
                box-sizing: border-box;
                background-color: #f5f5f5;
                padding: 15px;
                margin-top: 15px;
                font-size: 14px;
                color: #333;
                cursor: pointer;
                
                &:hover{
                    text-decoration: underline;
                    color: #0275d1;
                }
            }
        
        }

    }
 }
 
@media screen and (max-width: 991px) {
    .main .contentPart .module .greyLi{
        padding: 10px;
        margin-top: 10px;
    }
    .main .contentPart .module .greyBox_container{
        overflow-x: auto;
        overflow-y: hidden;
        border: 1px solid #ddd;
    }
    .main .contentPart .module .greyBox{
        min-width: 840px;
        // margin-bottom: 15px;
    }
}
</style>